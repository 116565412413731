@font-face {
    font-family: 'FarmNew';
    src: url('./fonts/FARM-NEW/FarmNew-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FarmNew';
    src: url('./fonts/FARM-NEW/FarmNew-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FarmNew';
    src: url('./fonts/FARM-NEW/FarmNew-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FarmNew';
    src: url('./fonts/FARM-NEW/FarmNew-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FarmNew';
    src: url('./fonts/FARM-NEW/FarmNew-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Baloo';
    src: url('./fonts/BALOO/Baloo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}