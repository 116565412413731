/* General Styles */
.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  background-color: #f8f8f8;
  color: #333;
  overflow-x: hidden;
}

/* Header Section */
.App-header {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.header-background {
  background: url('./images/viges-patron.svg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
}

.App-header h1 {
  font-size: 3em;
  margin: 20px 0;
}

.App-header p {
  font-size: 1.5em;
  margin-bottom: 20px;
}